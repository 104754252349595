<template>
  <div class="startup-detail">
    <div class="row">
      <!-- Top bar -->
      <div class="col-md-6 col-sm-12 mar-bot-big">
        <h3 class="card-title">
          <img class="avatar" src="img/placeholder.jpg" alt="..." />
          <span class="mar-left">
            <b>{{ this.name }}</b> <small>(startup)</small></span
          >
        </h3>
      </div>
      <div class="col-md-2 ml-auto">
        <router-link
          v-if="typeProg != 3"
          :to="{
            name: 'Startup-Edit',
            params: { startup_id: $route.params.startup_doc_id },
          }"
          replace
        >
          <base-button icon size="sm" class="btn-link">
            <i class="fas fa-edit"></i>
          </base-button>
        </router-link>

        <base-button
          v-if="typeProg != 3"
          icon
          @click.native="deleteItem()"
          size="sm"
          class="btn-link"
        >
          <i class="fas fa-trash"></i>
        </base-button>
      </div>
      <!-- End top bar -->

      <!-- main dashboard -->
      <div class="col-md-5 col-sm-12 mar-bot-big">
        <table>
          <tr>
            <td class="min-width">Type:</td>
            <td>
              <b>{{ this.type }}</b>
            </td>
          </tr>
          <tr>
          <tr>
            <td class="min-width">Stage:</td>
            <td>
              <b>{{ this.stage }}</b>
            </td>
          </tr>
          <tr>
            <td class="min-width">Website:</td>
            <td>
              {{ this.website }}
            </td>
          </tr>
          <tr>
            <td class="min-width">Initial capital($):</td>
            <td>
              {{ this.initialCap }}
            </td>
          </tr>
          <tr>
            <td class="min-width">Valuation($):</td>
            <td>
              {{ this.valuation }}
            </td>
          </tr>
          <tr>
            <td class="min-width">Program:</td>
            <td>
              <a href="#">
                <b>{{ this.program }}</b>
              </a>
            </td>
          </tr>
          <tr>
            <td class="min-width">End Date:</td>
            <td>
              <!-- <b>{{ this.deadline.toDate() | moment("MMM, DD YYYY") }}</b> -->
            </td>
          </tr>
          <tr>
            <td class="min-width">Created:</td>
            <td>{{ this.time }}</td>
          </tr>
          <br />
          <tr>
            <td class="min-width">Address:</td>
            <td>{{ this.address }}</td>
          </tr>
          <tr>
            <td class="min-width">City:</td>
            <td>{{ this.city }}</td>
          </tr>
          <br />
          <tr>
            <td class="min-width">Contact:</td>
            <td>
              {{ this.phone }} <br />
              {{ this.email }}
            </td>
          </tr>
        </table>
        <br />
        <span>{{ this.description }}</span>
      </div>
      <div class="col-md-2 col-sm-12">
        <div class="row stats-cards">
          <div
            class="col-md-12 col-6 stats-card"
            v-for="card in statsCards"
            :key="card.title"
          >
            <startup-stats-card
              :title="card.title"
              :sub-title="card.subTitle"
              :type="card.type"
              :icon="card.icon"
            >
            </startup-stats-card>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-sm-12">
        <card class="mar-small" type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="card-category">Progress(%)</h5>
                <!-- <h2 class="card-title">Performance</h2> -->
              </div>
              <div class="col-sm-6 d-flex d-sm-block">
                <div
                  class="
                    btn-group btn-group-toggle
                    float-right
                    startup-detail-chart-btn
                  "
                  data-toggle="buttons"
                >
                  <label
                    v-for="(option, index) in bigLineChartCategories"
                    :key="option.name"
                    class="btn btn-sm btn-primary btn-simple"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initBigChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="bigLineChart.activeIndex === index"
                    />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <i :class="option.icon"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :gradient-colors="bigLineChart.gradientColors"
              :gradient-stops="bigLineChart.gradientStops"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </div>
        </card>
        <router-link
          v-if="typeProg != 3"
          :to="{
            name: 'Startup-Progress-Edit',
            params: {
              program_id: $route.params.program_id,
              startup_doc_id: $route.params.startup_doc_id,
            },
          }"
          replace
        >
          <base-button size="sm" type="primary" simple
            ><i class="fas fa-edit"></i
          ></base-button>
        </router-link>
      </div>
      <!-- End main dashboard -->
      <!-- Timeline -->
      <div class="col-12">
        <div class="row">
          <div class="col-md-12" style="overflow-x: auto">
            <div class="swiper-container">
              <!-- <p class="swiper-control">
              <button type="button" class="btn btn-default btn-sm prev-slide">Prev</button>
              <button type="button" class="btn btn-default btn-sm next-slide">Next</button>
            </p> -->
              <div class="swiper-wrapper timeline">
                <div
                  class="swiper-slide"
                  v-for="item in steps"
                  :key="item.dateLabel"
                >
                  <div class="timestamp">
                    <span class="date">
                      {{ item.dateLabel.toDate() | moment("MMM DD, YYYY") }}
                    </span>
                  </div>
                  <div class="status">
                    <span>{{ item.title }}</span>
                  </div>
                </div>
              </div>
              <!-- TODO: just change the left value below to change the position of the indicator dynamically -->
              <span class="now" v-bind:style="{ left: timelineSize + '%' }">TO BE HERE</span>

              <!-- Add Pagination -->
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Timeline -->
      <div class="col-md-12 mar-top-big">
        <card class="card-plain startup-info">
          <tabs type="primary">
            <tab-pane label="Members">
              <div class="row">
                <div class="col-md-1 ml-auto">
                  <router-link
                    v-if="typeProg != 3"
                    :to="{
                      name: 'Meamber-Add',
                      params: {
                        program_id: $route.params.program_id,
                        startup_id: $route.params.startup_id,
                        startup_doc_id: $route.params.startup_doc_id,
                      },
                    }"
                    replace
                  >
                    <base-button type="danger" round icon>
                      <i class="fas fa-plus"></i>
                    </base-button>
                  </router-link>
                </div>
                <div class="col-sm-12">
                  <el-table
                    header-cell-class-name="table-transparent"
                    header-row-class-name="table-transparent"
                    row-class-name="table-transparent"
                    :data="meamberData"
                  >
                    <el-table-column
                      min-width="150"
                      sortable
                      label="Name"
                      property="name"
                    ></el-table-column>
                    <el-table-column
                      min-width="200"
                      sortable
                      label="Position"
                      property="position"
                    ></el-table-column>
                    <el-table-column
                      min-width="200"
                      sortable
                      label="Email"
                      property="email"
                    ></el-table-column>
                    <el-table-column
                      min-width="200"
                      sortable
                      label="Phone"
                      property="phone"
                    ></el-table-column>
                  </el-table>
                </div>
              </div>
            </tab-pane>

            <tab-pane label="Products">
              <div class="row">
                <div class="col-md-1 ml-auto">
                  <router-link
                    v-if="typeProg != 3"
                    :to="{
                      name: 'Product-Add',
                      params: {
                        program_id: $route.params.program_id,
                        startup_id: $route.params.startup_id,
                      },
                    }"
                    replace
                  >
                    <base-button type="danger" round icon>
                      <i class="fas fa-plus"></i>
                    </base-button>
                  </router-link>
                </div>
                <div class="col-sm-12">
                  <el-table
                    header-cell-class-name="table-transparent"
                    header-row-class-name="table-transparent"
                    row-class-name="table-transparent"
                    :data="productData"
                  >
                    <el-table-column
                      min-width="150"
                      sortable
                      label="Name"
                      property="name"
                    ></el-table-column>
                    <el-table-column
                      min-width="200"
                      sortable
                      label="Website"
                      property="website"
                    ></el-table-column>
                    <el-table-column
                      min-width="200"
                      sortable
                      label="Email"
                      property="email"
                    ></el-table-column>
                    <el-table-column
                      min-width="200"
                      sortable
                      label="Phone"
                      property="phone"
                    ></el-table-column>
                  </el-table>
                </div>
              </div>
            </tab-pane>

            <tab-pane label="Actions">
              <div class="row">
                <div class="col-md-1 ml-auto" v-if="typeProg != 3">
                  <router-link
                    :to="{
                      name: 'Action-Add',
                      params: {
                        program_id: $route.params.program_id,
                        startup_id: $route.params.startup_id,
                      },
                    }"
                    replace
                  >
                    <base-button type="danger" round icon>
                      <i class="fas fa-plus"></i>
                    </base-button>
                  </router-link>
                </div>
                <div class="col-sm-12">
                  <el-table
                    header-cell-class-name="table-transparent"
                    header-row-class-name="table-transparent"
                    row-class-name="table-transparent"
                    :data="actionData"
                  >
                    <el-table-column
                      min-width="150"
                      sortable
                      label="Message"
                      property="message"
                    ></el-table-column>
                    <el-table-column
                      min-width="200"
                      sortable
                      label="Link"
                      property="link"
                    >
                      <div slot-scope="{ row }">
                        <a
                          v-bind:href="row.link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {{ row.link }}
                        </a>
                      </div>
                    </el-table-column>
                    <el-table-column min-width="100" sortable label="Deadline">
                      <div slot-scope="{ row }">
                        <div v-if="row != null">
                          {{ row.deadline.toDate() | moment("MMM, DD YYYY") }}
                        </div>
                      </div>
                    </el-table-column>
                    <el-table-column
                      v-if="typeProg != 3"
                      min-width="200"
                      sortable
                      align="right"
                      header-align="right"
                      label="Tags"
                    >
                      <div slot-scope="{ row }">
                        <div v-if="row.status == 'created'">
                          <base-button
                            type="danger"
                            size="sm"
                            @click.native="actionSubmit(row.id)"
                            >Submit</base-button
                          >
                        </div>
                        <div v-if="row.status == 'waiting-for-approval'">
                          <base-button
                            type="success"
                            size="sm"
                            @click.native="actionAccept(row.id)"
                            >Accept</base-button
                          >
                          <base-button
                            type="danger"
                            size="sm"
                            @click.native="actionDecline(row.id)"
                            >Decline</base-button
                          >
                        </div>
                        <div v-if="row.status == 'accepted'">Completed</div>
                        <div v-if="row.status == 'declined'">
                          Your action have been declined, <br />
                          submit again <br />
                          <base-button
                            type="danger"
                            size="sm"
                            @click.native="actionSubmit(row.id)"
                            >Re-submit</base-button
                          >
                        </div>
                      </div>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </tab-pane>

            <tab-pane label="OKR">
              <div class="row">
                <div class="col-md-1 ml-auto">
                  <router-link
                    v-if="typeProg != 3"
                    :to="{
                      name: 'Startup-Milestone',
                      params: {
                        program_id: $route.params.program_id,
                        startup_id: $route.params.startup_id,
                        startup_doc_id: $route.params.startup_doc_id,
                      },
                    }"
                    replace
                  >
                    <base-button type="danger" round icon>
                      <i class="fas fa-plus"></i>
                    </base-button>
                  </router-link>
                </div>
                <div class="col-sm-12">
                  <el-table
                    header-cell-class-name="table-transparent"
                    header-row-class-name="table-transparent"
                    row-class-name="table-transparent"
                    :data="milestoneData"
                  >
                    <el-table-column
                      min-width="150"
                      sortable
                      label="Name"
                      property="name"
                    ></el-table-column>
                    <el-table-column
                      min-width="200"
                      sortable
                      label="Description"
                      property="description"
                    ></el-table-column>
                    <el-table-column
                      min-width="100"
                      sortable
                      label="Timeline"
                      property="workdays"
                    ></el-table-column>
                    <el-table-column
                      min-width="200"
                      sortable
                      align="center"
                      header-align="center"
                      label="Tags"
                    >
                      <div slot-scope="{ row }">
                        <div
                          v-for="tag in row.tags"
                          :key="tag"
                          class="but-margin"
                        >
                          <base-button type="danger" size="sm" simple>{{
                            tag
                          }}</base-button>
                        </div>
                      </div>
                    </el-table-column>
                    <el-table-column
                      v-if="typeProg != 3"
                      min-width="200"
                      sortable
                      align="right"
                      header-align="right"
                      label="Actions"
                    >
                      <div slot-scope="{ row }">
                        <div v-if="row.status == 'completed'">Completed</div>
                        <div v-if="row.status == 'created'">
                          <base-button
                            type="danger"
                            size="sm"
                            @click.native="milestoneComplete(row.id)"
                            >Completed</base-button
                          >
                        </div>
                      </div>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </tab-pane>
          </tabs>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem } from "src/components";
import { Table, TableColumn } from "element-ui";
import { ImageUpload } from "src/components/index";
import db from "@/components/Firebase/firebaseInit";
import firebase from "firebase";
import swal from "sweetalert2";
import VueMoment from "vue-moment";
import moment from "moment";
import StartupStatsCard from "src/components/Cards/startupStatsCard";

import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import * as chartConfigs from "@/components/Charts/config";
import config from "@/config";

// let bigChartData = [
//   [90, 70, 90, 70, 85, 30],
//   [80, 35, 95, 25, 95, 80],
// ]
let bigChartLabels = [
  "Business Model",
  "Finance",
  "Product Dev",
  "Marketing",
  "Team Building",
  "Fundraising",
];
let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.primary,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.primary,
  pointBorderColor: "rgba(255,255,255,0)",
  pointHoverBackgroundColor: config.colors.primary,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
};

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    TabPane,
    Tabs,
    StartupStatsCard,
    VueMoment,
    moment,
    Collapse,
    ImageUpload,
    CollapseItem,
    LineChart,
    BarChart,
  },
  computed: {
    bigLineChartCategories() {
      return [
        { name: "Initial", icon: "fas fa-calendar-minus2" },
        { name: "Now", icon: "fas fa-calendar-plus" },
      ];
    },
    timelineSize() {
      //  let leng = this.steps.length - 1;
      //  let day = new Date().getTime();

      //  let x = Number(this.steps[leng].dateLabel.seconds * 1000) - Number(this.steps[0].dateLabel.seconds * 1000);
      //  let y = Number(this.steps[leng].dateLabel.seconds * 1000) - Number(day);
      //  let z = (y * 84)/x;
      //  return z.toFixed(0);

      let segment = this.currentStage;

      let leng = this.steps.length;
      let diff = 200;
      let grandTotal = diff * leng;
      let x = diff * segment;
      let z = (x * 100) / grandTotal;
      return z;
    },
    // types() {
    //   let x = this.$store.state.models.type;
    //   let y;
    //   if (x == "admin") {
    //     y = 1;
    //   } else if (x == "program") {
    //     y = 2;
    //   } else if (x == "partner") {
    //     y = 3;
    //   } else if (x == "startup") {
    //     y = 4;
    //   }
    //   return y;
    // },
    typeProg() {
      let x = this.$store.state.models.type;
      let y;
      if (x == "admin") {
        y = 1;
      } else if (x == "program") {
        y = 2;
      } else if (x == "partner") {
        y = 3;
      } else if (x == "startup") {
        y = 4;
      }
      return y;
    },
  },
  data() {
    return {
      currentStage: null,
      deadline: null,
      steps: [],
      bigLineChart: {
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              ...bigChartDatasetOptions,
              data: [],
            },
          ],
          labels: bigChartLabels,
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: [],
      },
      statsCards: [
        {
          title: "0",
          subTitle: "Days Left",
          type: "primary",
          icon: "lni lni-calendar",
          // footer: '<i class="tim-icons icon-refresh-01"></i> Update Now'
        },
        {
          title: "92%",
          subTitle: "Performace",
          type: "primary",
          icon: "lni lni-bar-chart",
          // footer: '<i class="tim-icons icon-sound-wave"></i></i> Last Research'
        },
      ],
      inputRes: null,
      milestoneCompleted: [],
      name: null,
      startupName: null,
      phone: null,
      type: null,
      email: null,
      address: null,
      city: null,
      website: null,
      valuation: null,
      initialCap: null,
      jobs: null,
      profits: null,
      stage: null,
      assets: null,
      startups: null,
      time: null,
      description: null,
      program: null,
      images: {
        regular: null,
      },
      activeName: "first",
      tableData: [
        {
          id: 1,
          img: "img/tania.jpg",
          name: "Tania Mike",
          job: "Develop",
          progress: 25,
          since: 2013,
          salary: "€ 99,225",
        },
        {
          id: 2,
          img: "img/robi.jpg",
          name: "John Doe",
          job: "CEO",
          progress: 77,
          since: 2012,
          salary: "€ 89,241",
        },
        {
          id: 3,
          img: "img/lora.jpg",
          name: "Alexa Mike",
          job: "Design",
          progress: 41,
          since: 2010,
          salary: "€ 92,144",
        },
        {
          id: 4,
          img: "img/jana.jpg",
          name: "Jana Monday",
          job: "Marketing",
          progress: 50,
          since: 2013,
          salary: "€ 49,990",
        },
        {
          id: 5,
          img: "img/mike.jpg",
          name: "Paul Dickens",
          job: "Develop",
          progress: 100,
          since: 2015,
          salary: "€ 69,201",
        },
        {
          id: 6,
          img: "img/emilyz.jpg",
          name: "Manuela Rico",
          job: "Manager",
          progress: 15,
          since: 2012,
          salary: "€ 99,201",
        },
      ],
      milestoneData: [],
      actionData: [],
      productData: [],
      meamberData: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to.params.startup_doc_id + " sdf")
    db.collection("startups")
      .where(
        firebase.firestore.FieldPath.documentId(),
        "==",
        to.params.startup_doc_id
      )
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          next((vm) => {
            let data = [
              doc.data().businessModel,
              doc.data().finance,
              doc.data().productDev,
              doc.data().marketing,
              doc.data().teamBuilding,
              doc.data().fundraising,
            ];
            vm.bigLineChart.chartData.datasets[0].data = data;
          });
        });
      });

    db.collection("startups")
      .where("name", "==", to.params.startup_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          next((vm) => {
            let myDate = new Date(doc.data().time * 1000);
            console.log("asd:" + myDate);
            let progressOld;
            if (doc.data().totalMilestones == 0) {
              progressOld = 0;
            } else {
              progressOld =
                (doc.data().completedMilestones * 100) /
                doc.data().totalMilestones;
            }
            let progress = progressOld.toFixed(0) + "%";

            vm.id = doc.id;
            vm.name = doc.data().name;
            vm.type = doc.data().type;
            vm.address = doc.data().address;
            vm.email = doc.data().email;
            vm.phone = doc.data().phone;
            vm.stage = doc.data().stage;
            vm.city = doc.data().city;
            vm.website = doc.data().website;
            vm.description = doc.data().description;
            vm.program = doc.data().program;
            vm.statsCards[1].title = progress;

            vm.valuation = doc.data().valuation;
            vm.initialCap = doc.data().initialCap;
            vm.jobs = doc.data().jobs;
            vm.assets = doc.data().assets;
            vm.profits = doc.data().profits;
            vm.startups = doc.data().startups;

            var d = new Date();
            d.setTime(doc.data().time);
            let date = d.toDateString();
            vm.time = date;

            db.collection("programs")
              .where("name", "==", doc.data().program)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  vm.deadline = doc.data().end;

                  let today = new Date().getTime();
                  let date = doc.data().end.seconds * 1000;
                  let deadline = date - today;
                  let d = Math.ceil(deadline / (3600 * 24 * 1000));
                  let daysLeft;
                  if(d > 0 ){
                      daysLeft = d.toString();
                  }else{
                      daysLeft = '0';
                  }
                  vm.statsCards[0].title = daysLeft;

                  // console.log(deadline + ' days');
                });
              });

            db.collection("milestones")
              .orderBy("workdays")
              .where("program", "==", doc.data().programID)
              .get()
              .then((querySnapshot) => {
                // let x = this.currentStage;
                let i = 0;
                querySnapshot.forEach((doc) => {
                  let data = {
                    dateLabel: doc.data().workdays,
                    title: doc.data().name,
                  };
                  vm.steps.push(data);

                  let stepDate = doc.data().workdays.seconds * 1000;
                  let date = new Date().getTime();
                  if (stepDate < date) {
                    i++;
                    // console.log(i + " is one");
                  }
                  vm.currentStage = i;

                  // console.log(deadline + ' days');
                });
                // this.currentStage = i;
              });

            // vm.time = myDate;
          });
        });
      });
    db.collection("products")
      .where("startup_id", "==", to.params.startup_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          next((vm) => {
            const data = {
              name: doc.data().name,
              phone: doc.data().phone,
              email: doc.data().email,
              website: doc.data().website,
            };

            vm.productData.push(data);
          });
        });
      });

    db.collection("members")
      .where("startup_id", "==", to.params.startup_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          next((vm) => {
            const data = {
              name: doc.data().firstName + doc.data().lastName,
              phone: doc.data().phone,
              email: doc.data().email,
              position: doc.data().position,
            };

            vm.meamberData.push(data);
          });
        });
      });

    db.collection("milestones-startup")
      .where("startup", "==", to.params.startup_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          next((vm) => {
            const data = {
              id: doc.id,
              name: doc.data().name,
              description: doc.data().description,
              workdays: doc.data().workdays + " workdays",
              tags: doc.data().tags,
              status: doc.data().status,
            };

            vm.milestoneData.push(data);
          });
        });
      });
  },

  created() {
    this.getActions(this.$route.params.startup_id);
  },
  methods: {
    getActions(startupName) {
      console.log("getting actions");
      console.log(startupName);
      this.actionData = [];
      db.collection("actions")
        .where("startup_id", "==", startupName)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.id,
              message: doc.data().message,
              link: doc.data().link,
              deadline: doc.data().deadline,
              tags: doc.data().tags,
              status: doc.data().status,
            };
            // Make sure action data is empty before updating
            this.actionData.push(data);
          });
        });
    },
    deleteItem() {
      let jobsNew = Number(this.jobs);
      let profitsNew = Number(this.profits);
      let assetsNew = Number(this.assets);
      let initialCapNew = Number(this.initialCap);
      let valuationNew = Number(this.valuation);
      let startupNew = Number(this.startups);

      swal
        .fire({
          title: "Are you sure?",
          text: `Please confirm you want to delete. you cant revert once you have deleted the item.`,
          //   icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: "Confirm",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            //DB
            // Delete the Show
            db.collection("startups")
              .where(
                firebase.firestore.FieldPath.documentId(),
                "==",
                this.$route.params.startup_doc_id
              )
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  doc.ref.delete();
                  console.log("Module Deleted");
                });
              });

            db.collection("programs")
              .where(
                firebase.firestore.FieldPath.documentId(),
                "==",
                this.$route.params.program_id
              )
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  let jobs = Number(doc.data().jobs) - jobsNew;
                  let profits = Number(doc.data().profits) - profitsNew;
                  let assets = Number(doc.data().assets) - assetsNew;
                  let initialCap =
                    Number(doc.data().initialCap) - initialCapNew;
                  let valuation = Number(doc.data().valuation) - valuationNew;
                  let startups = Number(doc.data().startups) - 1;

                  doc.ref
                    .update({
                      jobs: jobs,
                      profits: profits,
                      assets: assets,
                      initialCap: initialCap,
                      valuation: valuation,
                      startups: startups,
                    })
                    .then(() => {
                      console.log("Completed");
                    });
                });
              });

            //DB
            swal.fire({
              title: "Completed!",
              text: "You have deleted the item",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            this.$router.push("/startups");
          }
        });
    },
    initBigChart(index) {
      let xx = this.$refs;
      let yy = this.bigLineChart;
      this.bigLineChart.activeIndex = index;
      db.collection("startups")
        .where(
          firebase.firestore.FieldPath.documentId(),
          "==",
          this.$route.params.startup_doc_id
        )
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (index == 0) {
              let data = [
                doc.data().businessModel,
                doc.data().finance,
                doc.data().productDev,
                doc.data().marketing,
                doc.data().teamBuilding,
                doc.data().fundraising,
              ];
              let chartData = {
                datasets: [
                  {
                    ...bigChartDatasetOptions,
                    data: data,
                  },
                ],
                labels: bigChartLabels,
              };
              console.log(chartData + " asd");
              xx.bigChart.updateGradients(chartData);
              yy.chartData = chartData;
            } else if (index == 1) {
              let data = [
                doc.data().businessModelNow,
                doc.data().financeNow,
                doc.data().productDevNow,
                doc.data().marketingNow,
                doc.data().teamBuildingNow,
                doc.data().fundraisingNow,
              ];

              let chartData = {
                datasets: [
                  {
                    ...bigChartDatasetOptions,
                    data: data,
                  },
                ],
                labels: bigChartLabels,
              };
              console.log(chartData + " asd");
              xx.bigChart.updateGradients(chartData);
              yy.chartData = chartData;
            }
          });
        });
    },
    // moment: function () {
    //    return moment();
    // },
    onImageChange(file) {
      this.images.regular = file;
    },
    actionSubmit(id) {
      swal
        .fire({
          title: "Are you sure?",
          text: `Please confirm you have completed the task`,
          //   icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: "Confirm",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            //DB
            db.collection("actions")
              .where(firebase.firestore.FieldPath.documentId(), "==", id)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  doc.ref
                    .update({
                      status: "waiting-for-approval",
                    })
                    .then(() => {
                      this.getActions(this.$route.params.startup_id);
                      console.log("Completed");
                    });
                });
              });
            //DB
            swal.fire({
              title: "Completed!",
              text: "You have completed an action",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            // this.$router.push("/startups");
          }
        });
    },
    actionAccept(id) {
      swal
        .fire({
          title: "Are you sure?",
          text: `Confirming to accept the action`,
          //   icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: "Confirm",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            //DB
            db.collection("actions")
              .where(firebase.firestore.FieldPath.documentId(), "==", id)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  doc.ref
                    .update({
                      status: "accepted",
                    })
                    .then(() => {
                      this.getActions(this.$route.params.startup_id);
                      console.log("Completed");
                    });
                });
              });
            //DB
            swal.fire({
              title: "Completed!",
              text: "You have accepted this action",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            // this.$router.push("/startups");
          }
        });
    },
    actionDecline(id) {
      swal
        .fire({
          title: "Are you sure?",
          text: `Confirming to decline the action`,
          //   icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: "Confirm",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            //DB
            db.collection("actions")
              .where(firebase.firestore.FieldPath.documentId(), "==", id)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  doc.ref
                    .update({
                      status: "declined",
                    })
                    .then(() => {
                      this.getActions(this.$route.params.startup_id);
                      console.log("Declined");
                    });
                });
              });
            //DB
            swal.fire({
              title: "Declined!",
              text: "You have declined this action",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            // this.$router.push("/startups");
          }
        });
    },
    milestoneComplete(id) {
      swal
        .fire({
          title: "Are you sure?",
          text: `Please confirm you have completed the milestone`,
          //   icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: "Confirm",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            //DB
            db.collection("milestones-startup")
              .where(firebase.firestore.FieldPath.documentId(), "==", id)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  doc.ref
                    .update({
                      status: "completed",
                    })
                    .then(() => {
                      console.log("Completed");
                    });
                });
              });
            db.collection("startups")
              .where(
                firebase.firestore.FieldPath.documentId(),
                "==",
                this.$route.params.startup_doc_id
              )
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  let milestone = Number(doc.data().completedMilestones) + 1;
                  doc.ref
                    .update({
                      completedMilestones: milestone,
                    })
                    .then(() => {
                      console.log("Completed");
                    });
                });
              });

            //DB
            swal.fire({
              title: "Completed!",
              text: "You have completed the milestone",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            this.$router.push("/startups");
          }
        });
    },
  },
  mounted() {
    // var swiper = new Swiper(".swiper-container", {
    //   // paginxation: '.swiper-pagination',
    //   slidesPerView: 4,
    //   paginationClickable: true,
    //   grabCursor: true,
    //   paginationClickable: true,
    //   pagination: {
    //     el: ".swiper-pagination",
    //     clickable: true,
    //   },
    //   navigation: {
    //     nextEl: ".next-slide",
    //     prevEl: ".prev-slide",
    //   },
    // });
  },
};
</script>
<style lang="scss">
.startup-detail {
  .startup-detail-chart-btn {
    .btn.active {
      background-image: none !important;
      background-color: var(--primary) !important;
    }
  }
  .stats-cards {
    .stats-card {
      &:nth-child(1) {
        .card {
          background-image: linear-gradient(
            to bottom,
            rgba(119, 175, 237, 0.8),
            rgba(248, 214, 238, 1)
          );
          .info-icon {
            border-radius: 10px;
            background-color: rgb(119, 175, 237);
          }
          .numbers {
            p {
              color: white;
              font-size: 1rem;
            }
            h1 {
              font-weight: 600;
              opacity: 0.7;
            }
          }
        }
      }
      &:nth-child(2) {
        .card {
          background-image: linear-gradient(
            to bottom,
            rgba(247, 162, 151, 0.8),
            rgba(255, 221, 155, 1)
          );
          .info-icon {
            border-radius: 10px;
            background-color: rgb(247, 162, 151);
          }
          .numbers {
            p {
              color: white;
              font-size: 1rem;
            }
            h1 {
              font-weight: 600;
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
  .startup-info {
    .nav-link {
      background-image: none !important;
    }
  }
  .mar-small {
    margin-bottom: 5px !important;
  }
  .extended-forms .progress {
    margin-bottom: 30px;
  }
  .but-margin {
    margin-bottom: 6px;
  }
  .mar-left {
    margin-left: 10px;
  }
  .min-width {
    width: 60%;
    white-space: nowrap;
  }
  .card {
    border-radius: 1.2rem !important;
  }
  .mar-bot-big {
    margin-bottom: 50px;
  }
  .mar-top-big {
    margin-top: 30px;
  }

  // Timeline

  body {
    background: #e8eeff;
  }
  // #app {
  //   padding: 50px 0;
  // }
  .timeline {
    margin: 20px 0;
    list-style-type: none;
    display: flex;
    padding: 0;
    text-align: center;
    // overflow-x: scroll;
    width: min-content;
  }
  .timeline li {
    transition: all 200ms ease-in;
  }
  .timestamp {
    width: 200px;
    margin-bottom: 20px;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 100;
  }
  .status {
    padding: 0px 40px;
    display: flex;
    justify-content: center;
    // border-image: linear-gradient(90deg, yellow, green) 1;
    // border-top: 4px solid;
    position: relative;
    transition: all 200ms ease-in;
  }
  .swiper-container {
    padding-bottom: 2em;
    width: min-content;
    overflow: hidden;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 0.375rem;
      border-radius: 0.25rem;
      background-image: linear-gradient(
        to right,
        rgb(247, 181, 0),
        rgb(199, 0, 255),
        rgb(119, 175, 237)
      );
      position: absolute;
      top: 4em;
    }
    .now {
      position: absolute;
      top: 11em;
      mix-blend-mode: multiply;
      font-size: 0.7em;
      padding: 2px 2em;
      margin-top: 1em;
      color: white;
      background: grey;
      border-radius: 2em;
      z-index: 1;
      &::before {
        content: "";
        top: -5px;
        z-index: -1;
        margin: auto;
        width: 15px;
        display: block;
        transform: translateX(-50%) rotate(45deg);
        height: 15px;
        position: absolute;
        left: 50%;
        background: grey;
      }
    }
  }

  .status span {
    font-weight: 600;
    padding-top: 20px;
  }
  .status span:before {
    content: "";
    width: 25px;
    height: 25px;
    background-color: var(--primary);
    border-radius: 25px;
    border: 4px solid #fff;
    position: absolute;
    top: -15px;
    left: 42%;
    transition: all 200ms ease-in;
  }
  .swiper-control {
    text-align: right;
  }
}
</style>
