<template>
  <card class="card-stats" :show-footer-line="true">
    <div class="row">
      <div class="col-12" v-if="$slots.icon || icon">
        <div class="info-icon text-center" :class="`icon-${type}`">
          <slot name="icon"> <i :class="icon"></i> </slot>
        </div>
      </div>
      <div class="col-12" v-if="$slots.content || title || subTitle">
        <div class="numbers">
          <slot>
            <p v-if="subTitle" class="card-category mar-top"><b>{{ subTitle }}</b></p>
            <h1 v-if="title" class="card-title mar-big-top"><b>{{ title }}</b></h1>
          </slot>
        </div>
      </div>
    </div>
    <div class="stats" slot="footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </card>
</template>
<script>
import Card from './Card.vue';

export default {
  name: 'startup-stats-card',
  components: {
    Card
  },
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    icon: String,
    title: String,
    subTitle: String
  }
};
</script>
<style>
.card-stats .numbers {
    text-align: left !important;
}
.mar-top{
    margin-top: 5px;
}
.mar-big-top{
    margin-top: 10px;
}
</style>
