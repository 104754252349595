<template>
  <div>
    <admin-dashboard v-if="type == 1"></admin-dashboard>
    <program-dashboard v-if="type == 2"></program-dashboard>
    <partner-dashboard v-if="type == 3"></partner-dashboard>
    <startup-dashboard v-if="type == 4"></startup-dashboard>
  </div>
</template>
<script>
import AdminDashboard from "./adminDashboard.vue";
import ProgramDashboard from "./programDashboard.vue";
import PartnerDashboard from "./partnerDashboard.vue";
import StartupDashboard from "./startupDashboard.vue";


export default {
  components: {
    AdminDashboard,
    ProgramDashboard,
    PartnerDashboard,
    StartupDashboard
  },
  computed: {
    type(){
      let x = this.$store.state.models.type;
      let y;
      if(x == 'admin'){
        y = 1;
      }else if(x == 'program'){
        y = 2;
      }else if(x == 'partner'){
        y = 3;
      }else if(x == 'startup'){
        y = 4;
      }
       return y;
    },
  }
};
</script>
<style lang="scss">
</style>
