<template>
  <div>
    <div class="row">
      <div class="col-md-8 col-sm-12 startup-col-container">
        <div class="startups-incubated">
          <b>Startups Incubated</b>
          <p>{{ startupNo }}</p>
        </div>
        <card class="mar-small" type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
                <h1 class="card-title mar-bot-small">
                  {{ selectedProgram }} Progress
                </h1>
                <el-select
                  class="select-primary program-selector"
                  size="large"
                  placeholder="List of programs"
                  v-model="selectedProgram"
                  @change="programDataFetch()"
                >
                  <el-option
                    v-for="option in programList"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  >
                  </el-option>
                </el-select>
                <!-- <h5 class="card-category">Progress(%)</h5> -->
                <!-- <h2 class="card-title">Performance</h2> -->
              </div>
              <div class="col-sm-6 d-flex d-sm-block">
                <div
                  class="btn-group chart-data-toggle btn-group-toggle float-right"
                  data-toggle="buttons"
                >
                  <label
                    v-for="(option, index) in bigLineChartCategories"
                    :key="option.name"
                    class="btn btn-sm btn-primary btn-simple"
                    :class="{ active: bigLineChart.activeIndex === index }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initBigChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="bigLineChart.activeIndex === index"
                    />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <i :class="option.icon"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <hr />
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :gradient-colors="bigLineChart.gradientColors"
              :gradient-stops="bigLineChart.gradientStops"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </div>
        </card>
      </div>
      <div class="col-md-4 col-sm-12">
        <h1 class="card-title">Dashboard</h1>
        <p class="card-description">{{ this.date | moment("MMM, DD YYYY") }}</p>
        <hr />
        <card class="card-plain" body-classes="table-full-width">
          <p class="card-description">Startup Value</p>
          <h1 class="card-title">$ {{ this.valuationNo || '0'}}</h1>
          <el-table
            header-cell-class-name="table-transparent"
            header-row-class-name="table-transparent"
            row-class-name="table-transparent"
            :data="tableData"
          >
            <el-table-column
              min-width="100"
              label="Type"
              property="name"
            ></el-table-column>
            <el-table-column
              min-width="100"
              label="$"
              property="amount"
            ></el-table-column>
            <el-table-column
              min-width="100"
              label="%"
              property="percentage"
            ></el-table-column>
          </el-table>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <stats-card
          :title="startupNo"
          sub-title="Startups"
          type="primary"
          icon="lni lni-bulb"
        >
        </stats-card>
      </div>
      <div class="col-lg-3 col-md-6">
        <stats-card
          :title="usdNo"
          sub-title="USD Invested"
          type="primary"
          icon="lni lni-investment"
        >
        </stats-card>
      </div>
      <div class="col-lg-3 col-md-6">
        <stats-card
          :title="partnersNo"
          sub-title="Partners"
          type="primary"
          icon="lni lni-users"
        >
        </stats-card>
      </div>
      <div class="col-lg-3 col-md-6">
        <stats-card
          :title="jobsNo"
          sub-title="Jobs Created"
          type="primary"
          icon="lni lni-network"
        >
        </stats-card>
      </div>
      <!-- Big Chart -->
      <!-- <div class="col-md-8 col-sm-12">
      <card type="chart">
        <template slot="header">
          <div class="row">
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h5 class="card-category">Startup</h5>
              <h2 class="card-title">Performance</h2>
            </div>
            <div class="col-sm-6 d-flex d-sm-block">
              <div
                class="btn-group btn-group-toggle"
                :class="isRTL ? 'float-left' : 'float-right'"
                data-toggle="buttons"
              >
              </div>
            </div>
          </div>
        </template>
        <div class="chart-area">
          <bar-chart
            style="height: 100%"
            ref="bigChart"
            :chart-data="barChart2.chartData"
            :extra-options="barChart2.extraOptions"
            :height="200"
          >
          </bar-chart>
        </div>
      </card>
    </div> -->
    </div>
  </div>
</template>
<script>
import db from "@/components/Firebase/firebaseInit";
import firebase from "firebase";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import { Table, TableColumn } from "element-ui";
import * as chartConfigs from "@/components/Charts/config";
import TaskList from "./TaskList";
import UserTable from "./UserTable";
import CountryMapCard from "./CountryMapCard";
import StatsCard from "src/components/Cards/StatsCard";
import config from "@/config";
import { Select, Option } from "element-ui";

let bigChartLabels = [
  "Business Model",
  "Finance",
  "Product Dev",
  "Marketing",
  "Team Building",
  "Fundraising",
];

let bigChartDatasetOptions = {
  fill: true,
  borderColor: "#fff",
  borderWidth: 6,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: "#fff",
  pointBorderColor: "rgb(242, 71, 66)",
  pointHoverBackgroundColor: config.colors.primary,
  pointBorderWidth: 4,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 10,
};

export default {
  name: "AdminDashboard",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Option.name]: Option,
    [Select.name]: Select,
    LineChart,
    BarChart,
    StatsCard,
    TaskList,
    CountryMapCard,
    UserTable,
  },
  data() {
    return {
      selectedProgram: null,
      startupNo: null,
      valuationNo: null,
      usdNo: null,
      jobsNo: null,
      partnersNo: null,
      date: new Date(),
      programList: [],
      tableData: [],
      bigLineChart: {
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              ...bigChartDatasetOptions,
              data: [],
            },
          ],
          labels: bigChartLabels,
        },
        extraOptions: chartConfigs.iceChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: [],
      },
    };
  },
  created() {
    //Programs
    console.log(this.$store.state.models.type + " is the type");
    db.collection("programs")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let incubated;
          if (doc.data().startups == null) {
            incubated = 0;
          } else {
            incubated = doc.data().startups;
          }
          this.selectedProgram = doc.data().programID;
          console.log('selecting program')
          console.log(this.selectedProgram)
          this.startupNo = doc.data().startups;
          // this.usdNo;
          this.partnersNo = doc.data().partner.length;
          this.jobsNo = doc.data().jobs;
          this.usdNo = doc.data().initialCap;

          let total =
            Number(doc.data().initialCap) +
            Number(doc.data().profits) +
            Number(doc.data().assets);
          let initialCapPer = (Number(doc.data().initialCap) * 100) / total;
          let profitsPer = (Number(doc.data().profits) * 100) / total;
          let assetsPer = (Number(doc.data().assets) * 100) / total;

          (this.tableData = [
            {
              name: "Funds",
              amount: doc.data().initialCap,
              percentage: initialCapPer.toFixed(2) + "%",
            },
            {
              name: "Profits",
              amount: doc.data().profits,
              percentage: profitsPer.toFixed(2) + "%",
            },
            {
              name: "Assets",
              amount: doc.data().assets,
              percentage: assetsPer.toFixed(2) + "%",
            },
          ]),
            (this.valuationNo = doc.data().valuation);

          const data = {
            id: doc.id,
            name: doc.data().name,
          };
          this.programList.push(data);
        });
      });
    this.initBigChart(0);
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return [
        { name: "Initial", icon: "fas fa-calendar-minus" },
        { name: "Now", icon: "fas fa-calendar-plus" },
      ];
    },
  },
  methods: {
    programDataFetch() {
      //access db
   console.log(`program data fetch`)
      console.log(firebase.firestore)
    if (this.selectedProgram != null){
        db.collection("programs")
        .where(
          firebase.firestore.FieldPath.documentId(),
          
          "==",
          this.selectedProgram
        )
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log("this is");
            this.startupNo = doc.data().startups;
            this.partnersNo = doc.data().partner.length;
            this.jobsNo = doc.data().jobs;
            this.usdNo = Number(doc.data().initialCap || 0) ;

            let usdNo = this.usdNo;
            let profits = Number(doc.data().profits || 0)
            let assets = Number(doc.data().assets || 0)

            let total =
              usdNo + profits + assets;
            let initialCapPer = (usdNo * 100) / (total || 1);
            let profitsPer = (profits * 100) / (total || 1);
            let assetsPer = (assets * 100) / (total || 1);

            (this.tableData = [
              {
                name: "Funds",
                amount: usdNo,
                percentage: initialCapPer.toFixed(2) + "%",
              },
              {
                name: "Profits",
                amount: profits,
                percentage: profitsPer.toFixed(2) + "%",
              },
              {
                name: "Assets",
                amount: assets,
                percentage: assetsPer.toFixed(2) + "%",
              },
            ]),
              (this.valuationNo = doc.data().valuation);
          });
        });
    }
      // return 100;
      this.initBigChart(0);
    },
    initBigChart(index) {
      let xx = this.$refs;
      let yy = this.bigLineChart;
      this.bigLineChart.activeIndex = index;
    
      if (this.selectedProgram != null) {
          console.log(`int big cahrt `)
      console.log(this.selectedProgram)
         db.collection("programs")
        .where(
          firebase.firestore.FieldPath.documentId(),
          "==",
          this.selectedProgram
        )
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (index == 0) {
              let data = [
                doc.data().businessModel / doc.data().startups,
                doc.data().finance / doc.data().startups,
                doc.data().productDev / doc.data().startups,
                doc.data().marketing / doc.data().startups,
                doc.data().teamBuilding / doc.data().startups,
                doc.data().fundraising / doc.data().startups,
              ];
              let chartData = {
                datasets: [
                  {
                    ...bigChartDatasetOptions,
                    data: data,
                  },
                ],
                labels: bigChartLabels,
              };
              console.log(chartData + " asd");
              xx.bigChart.updateGradients(chartData);
              yy.chartData = chartData;
            } else if (index == 1) {
              let data = [
                doc.data().businessModelNow / doc.data().startups,
                doc.data().financeNow / doc.data().startups,
                doc.data().productDevNow / doc.data().startups,
                doc.data().marketingNow / doc.data().startups,
                doc.data().teamBuildingNow / doc.data().startups,
                doc.data().fundraisingNow / doc.data().startups,
              ];

              let chartData = {
                datasets: [
                  {
                    ...bigChartDatasetOptions,
                    data: data,
                  },
                ],
                labels: bigChartLabels,
              };
              console.log(chartData + " asd");
              xx.bigChart.updateGradients(chartData);
              yy.chartData = chartData;
            }
          });
        });
      }
     
    },
  },
  mounted() {
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = "ar";
      this.$rtl.enableRTL();
    }
    this.initBigChart(0);
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = "en";
      this.$rtl.disableRTL();
    }
  },
};
</script>
<style lang="scss">
.startup-col-container {
  @media only screen and (max-width: 768px) {
    padding: 0px !important;
    margin-bottom: 20px;
  }
  .card-title {
    margin-bottom: 0px;
  }
  .card-chart {
    background-color: rgb(242, 71, 66) !important;
    margin-left: -50px;
    margin-top: -50px;
    padding: 50px;
    border-radius: 0px;
    border-bottom-right-radius: 100px;
    @media only screen and (max-width: 768px) {
      margin: initial;
      margin-top: -20px;
      padding: initial;
      padding-right: 50px;
      padding-top: 100px;
      border-bottom-right-radius: 0px;
    }

    &::before,
    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 60%;
      height: 60%;
      background-image: linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(255, 255, 255, 0.3)
      );
      opacity: 0.5;
      border-radius: 3rem;
      transform: rotate(-45deg);
      left: -43%;
      top: 70%;
    }
    &::after {
      top: -50%;
      left: 50%;
      transform: rotate(45deg);
    }

    .card-header {
      z-index: 1;
      hr {
        border-top: 1px solid rgba(255, 255, 255, 0.5);
      }
      h1 {
        color: white !important;
        font-weight: bold !important;
        margin-bottom: 35px !important;
      }
      .dropdown {
        position: relative !important;
        margin-left: 15px !important;
        width: 150px !important;
        top: 0px !important;
      }
      .el-select {
        .el-input {
          input {
            border-color: white !important;
            color: white;
          }
          .el-input__icon {
            color: white;
          }
        }
      }
      .chart-data-toggle {
        .btn-simple:not(.active) {
          background-color: transparent !important;
          background-image: none !important;
          border-color: white !important;
          color: white;
        }
        .active {
          background-image: none !important;
          background-color: white !important;
          color: rgb(242, 71, 66) !important;
        }
      }
    }
    .card-body {
      z-index: 1;
    }
  }
  .dropdown-top {
    padding: 4px !important;
  }
  .mar-bot-small {
    margin-bottom: 10px;
  }
  .startups-incubated {
    position: absolute;
    width: 7rem;
    height: 7rem;
    top: calc(50% - 3.5rem);
    right: 0rem;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;
    padding: 10px;
    border-radius: 50%;
    text-align: center;
    font-size: 2.25rem;
    line-height: 2.25rem;
    box-shadow: 0 0.5rem 3.5rem 0 rgba(242, 71, 66, 0.4);
    @media only screen and (max-width: 768px) {
      top: 28%;
    }
    @media only screen and (max-width: 575px) {
      top: 12%;
    }
    b {
      opacity: 0.5;
      text-transform: uppercase;
      font-size: 0.625rem;
      line-height: 0.625rem;
      margin-bottom: 5px;
    }
    p {
      font-weight: bold;
    }
    &::after {
      display: block;
      content: "";
      position: absolute;
      width: calc(100% + 2rem);
      height: calc(100% + 2rem);
      border-radius: 50%;
      border: dotted 0.375rem rgba(247, 162, 151, 0.5);
    }
  }
}

.card-stats .info-icon.icon-primary {
  background-image: none;
}
</style>
